import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Heading = styled.h1`
  font-size: 50px;
  margin-top: -15vh;
`

const NotFoundPage = () => (
  <Wrapper>
    <Heading>Oops!</Heading>
    <p>The page has gone</p>
  </Wrapper>
)

export default NotFoundPage
